@font-face {
  font-family: "Gilroy-Bold";
  src: url("../../public/Assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("../../public/Assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url("../../public/Assets/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

:root {
  --btp-primary-clr: #ff5a5f;
  --btp-primary50-clr: #fba8aa;
  --btp-primary20-clr: #ffdedf;
  --btp-img-background-clr: #ffeeef;
  --btp-black-clr: #495057;
  --btp-gray-clr: #92969a;
  --btp-white-clr: #ffffff;
  --btp-black-overlay-clr: #4950571a;
  --btp-white-overlay-clr: #ffffff1a;
  --btp-border: #dddddd;
  --btp-border-disabled: #cdcdcd;
  --btp-background-overlay: #49505773;
  --btp-white-hover: #cdcdcd;
  --btp-success-clr: #2ab450;
  --btp-ready-clr: #ffbe86;
  --btp-error-clr: #cd142d;
  --btp-gilroy-medium: "Gilroy-Medium";
  --btp-gilroy-bold: "Gilroy-Bold";
  --btp-gilroy-regular: "Gilroy-Regular";
}

body {
  font-family: "Gilroy-Bold";
}

/* div {
  font-family: "Gilroy-Bold";
  color: var(--btp-black-clr) !important;
} */

p {
  margin-bottom: 0 !important;
}

.input-group-text {
  border-left: none !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  height: 40px;
  padding: 0.96875rem 0.75rem 0.96875rem 1rem !important;
}

.form-control {
  width: 20rem;
  padding: 0.96875rem 0.75rem 0.96875rem 1rem !important;
  border-radius: 0.5rem !important;
  height: 40px;
  margin-top: 1.25rem;
}

.input-form {
  margin-bottom: 0.25rem;
}

.container {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: var(--btp-gray-clr) !important;
}

.input-group .form-control:focus {
  box-shadow: none;
  border-color: var(--btp-gray-clr) !important;
}

.input-group .form-control:focus + .flag-dropdown {
  box-shadow: none;
  border: 1.5px solid var(--btp-gray-clr) !important;
  border-right: none !important;
}

.input-group .form-control:hover + .flag-dropdown {
  box-shadow: none;
  border: 1.5px solid var(--btp-white-hover) !important;
  border-right: none !important;
}

.btn {
  width: -webkit-fill-available !important;
  padding: 0.875rem 1.25rem !important;
  gap: 0.625rem;
  font-size: 1rem;
}

.styled-btn {
  border-radius: 3.125rem !important;
  background: var(--btp-primary-clr) !important;
  border: var(--btp-primary-clr) !important;
  margin-top: 1.5rem;
}

.white-btn {
  border-radius: 3.125rem !important;
  background: var(--btp-white-clr) !important;
  /* border: white !important; */
  color: var(--btp-black-clr) !important;
  border-radius: 3.125rem !important;
  border: 1px solid var(--btp-border) !important;
}

.text-btn {
  color: var(--btp-primary-clr);
  font-family: var(--btp-gilroy-medium);
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 128.571% */
}

.slashed-text {
  display: flex;
  flex-direction: row;
  font-family: "Gilroy-MediumItalic" !important;
  margin-top: 2.31rem;
  margin-bottom: 2.12rem;
  color: var(--btp-black-clr);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.slashed-text:before,
.slashed-text:after {
  color: var(--btp-border);
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.slashed-text:before {
  margin-right: 0.78rem;
}
.slashed-text:after {
  margin-left: 0.78rem;
}

.apple-login {
  margin-top: 1.25rem !important;
}

button {
  font-family: var(--btp-gilroy-medium) !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.25rem !important;
}

.login-by-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.create-account {
  text-decoration: none;
  color: var(--btp-primary-clr);
  font-family: var(--btp-gilroy-medium);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.create-account-container {
  color: var(--black, #495057);
  text-align: center;
  font-family: var(--btp-gilroy-regular);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.otp-box input {
  height: 3rem !important;
  width: 3rem !important;
  border-radius: 0.5rem;
  border: 1px solid var(--btp-border);
}

.otp-box input:hover {
  border: 1px solid var(--btp-white-hover) !important;
}

.error-otp-field input {
  border: 1px solid var(--btp-error-clr) !important;
}

.otp-box input:focus {
  /* border: 1px solid var(--btp-primary-clr) !important; */
  border: none !important;
}

.otp-box input:focus-visible {
  outline: var(--btp-gray-clr) auto 1px;
}

.text-btn {
  background: none !important;
  border: none !important;
  /* margin-top: 1.25rem; */
}

.sub-text {
  color: var(--btp-gray-clr);
  font-family: "Euclid Square";
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  margin-top: 0.5rem !important;
  margin-bottom: 2rem !important;
}

.highlighted-sub-text {
  color: var(--black, #495057);
  font-weight: 500 !important;
  margin-top: 0.75rem;
  margin-bottom: 2.25rem;
}

.server-error {
  color: var(--black, #495057);
  font-family: var(--btp-gilroy-medium);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
  padding-left: 1.25rem;
}

.error-msg {
  color: var(--btp-error-clr);
  margin-top: 0.25rem !important;
  margin-bottom: 0;
  font-size: 0.875rem !important;
}

.server-error-container {
  border-left: 0.25rem solid var(--btp-error-clr);
  width: -webkit-fill-available;
  height: 2.5rem;
  background: #fdf6f7;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.forgot-btn {
  margin-top: 0.75rem !important;
  margin-bottom: 1.5rem !important;
}

.planning-btn {
  margin-bottom: 1.25rem;
  display: inline-flex;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: fit-content !important;
}

/* Add this CSS in your component's CSS file or in a global CSS file */
.planning-btn.active {
  background-color: var(
    --btp-primary20-clr
  ) !important; /* Change to your desired active background color */
  color: var(
    --btp-primary-clr
  ) !important; /* Change to your desired active text color */
  border-color: var(
    --btp-primary-clr
  ) !important; /* Change to your desired active border color */
}

.planning-btn.active:hover {
  background-color: #0056b3; /* Change to your desired active hover background color */
  border-color: #0056b3; /* Change to your desired active hover border color */
}

.planning-btn-container {
  display: flex;
  flex-direction: column;
}

.gender-btn-container {
  display: flex;
  margin-top: 0.75rem;
  justify-content: start;
  margin-top: 1rem;
  flex-direction: column;
}

.gender-btn {
  display: flex !important;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 0.5rem !important;
  border: 1px solid var(--btp-border);
  background: var(--btp-white-clr);
  width: -webkit-fill-available !important;
  height: 7.5rem !important;
  justify-content: start;
}

.gender-label {
  /* margin-top: 1rem; */
  margin-left: 1.25rem;
  color: var(--btp-black-clr);
  font-family: var(--btp-gilroy-medium);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.15388rem; /* 102.566% */
  letter-spacing: 0.045rem;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 3rem !important;
  border: 1px solid var(--btp-border) !important;
  padding: 0.96875rem 0.75rem 0.96875rem 3.5rem !important;
  color: var(--btp-black-clr) !important;
  font-family: var(--btp-gilroy-medium) !important;
  font-size: 1rem !important;
  /* font-style: normal !important; */
  font-weight: 400 !important;
  /* line-height: 1.25rem !important; */
}

.react-tel-input .flag-dropdown {
  background: var(--btp-white-clr) !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  border: 1px solid var(--btp-border) !important;
  border-right: none !important;
  padding-left: 0.75rem !important;
}

.react-tel-input .country-list {
  width: 20rem !important;
}

.react-tel-input .country {
  padding: 0.5rem 1rem !important;
}

.page-heading {
  color: var(--btp-black-clr);
  font-family: var(--btp-gilroy-bold);
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2rem;
}

.children-btn-container > .planning-btn {
  margin-right: 0.75rem;
  margin-bottom: 0;
  padding: 1.25rem 2rem;
}

/* .continue-btn {
  position: relative;
  bottom: 2rem; 
  width: -webkit-fill-available;
} */

.bottom-btn-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 90vh;
}

.apple-auth-btn {
  width: 100%;
  border-radius: 0.5rem !important;
  margin-top: 1.25rem;
  border-radius: 3.125rem !important;
  height: 56px;
  border: 1px solid var(--btp-border) !important;
}

.apple-auth-btn svg {
  margin-right: 0.6rem;
}

.iframe > #container {
  padding: 0 !important;
  width: 100% !important;
}

.image-container {
  display: none;
  /* opacity: 0.1; */
  background: var(--btp-img-background-clr);
}

.nav-container {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.gender-btn.active {
  /* background-color: var(
    --btp-primary20-clr
  ) !important;  */
  color: var(
    --btp-primary-clr
  ) !important; /* Change to your desired active text color */
  border-color: var(
    --btp-primary-clr
  ) !important; /* Change to your desired active border color */
}

.index-num {
  color: var(--btp-black-clr);
  font-family: var(--btp-gilroy-bold);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.stage-btn {
  vertical-align: middle;
  border-radius: 0.5rem;
  border: 1px solid var(--btp-border);
  background: var(--btp-white-clr);
  padding: 0;
  display: flex;
  padding-inline: 10px;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}

.stage-btn:hover:not(.active) {
  color: #ff4d4f;
  border-color: #ff4d4f;
  cursor: pointer;
}

.stage-btn.active {
  background-color: #ff4d4f63;
  border-color: #ff4d4f;
  /* color: white; */
}

/* Add the following rule to override the hover state for the active class */
.stage-btn.active:hover {
  color: inherit;
  border-color: #ff4d4f;
  cursor: default;
}

.stage-btn > img {
  height: 3.5rem;
}

.stage-btn-text {
  height: 4rem !important;
  display: flex;
  align-items: center;
  padding-right: 2rem;
}

.form-control:hover {
  border: 1.5px solid var(--btp-white-hover);
}

.form-control:hover + .input-group-text {
  border: 1.5px solid var(--btp-white-hover);
}
.form-control:focus {
  border: 1.5px solid var(--btp-gray-clr);
}
.form-control:focus + .input-group-text {
  border: 1.5px solid var(--btp-gray-clr);
}

/* .form-control:focus > .input-group-text {
  border: 1.5px solid var(--btp-gray-clr);
} */

.error-field {
  border: 1.5px solid var(--btp-error-clr) !important;
}

.input-group-text {
  margin-top: 1.25rem;
  border-left: none !important;
}

.password-input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: none !important;
}

.female-btn {
  margin-right: 0;
  margin-bottom: 1.8rem;
}

.gender-btn img {
  width: 5rem;
  height: 5rem;
}

@media (min-width: 970px) {
  .desktop-container {
    display: flex;
    flex-direction: row;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }

  .container {
    width: 50% !important;
  }

  .desktop-container {
    height: 90.9vh;
  }

  .form-container {
    padding: 0 !important;
    max-width: 400px !important;
    overflow-y: scroll;
    /* padding-inline: 8rem !important; */
    /* overflow-y: scroll !important; */
    /* display: flex;
    align-items: center; */
  }

  .form-control::placeholder {
    color: #92969a !important;
  }

  .children-details-container {
    overflow-y: scroll;
  }

  .forgot-btn {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }

  .styled-btn {
    margin-top: 2rem !important;
    color: var(--btp-white-clr);
    font-family: var(--btp-gilroy-medium) !important;
    font-size: 1.125rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem !important; /* 133.333% */
  }

  .page-heading {
    /* margin-top: 6rem; */
  }

  .form-control {
    margin-top: 1.5rem;
    height: 56px;
    border-radius: 0.5rem !important;
  }

  .password-input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right: none !important;
  }

  .input-group-text {
    margin-top: 1.5rem;
    height: 56px;
    border-bottom-right-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .btn {
    height: 56px;
    font-size: 1.125rem;
  }

  .text-btn {
    font-size: 1rem;
  }

  .create-account {
    font-size: 1.125rem;
  }

  .create-account-container {
    font-size: 1.125rem;
  }

  .sub-text {
    font-size: 1rem !important;
    margin-top: 0.75rem !important;
    margin-bottom: 2.25rem !important;
  }

  .vertically-center {
    display: flex;
    flex-direction: column;
    /* align-items: space-around; */
  }

  .otp-box input {
    height: 4rem !important;
    width: 4rem !important;
    margin-right: 0 !important;
  }

  .otp-box {
    justify-content: space-between;
  }

  .react-tel-input .flag-dropdown {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .react-tel-input .form-control {
    height: 56px !important;
    color: var(--btp-gray-clr) !important;
  }

  .modal-dialog {
    width: 20rem !important;
    height: 18.375rem !important;
  }

  .login-container {
    display: grid;
    place-items: center;
  }
}

.ant-btn-default:hover {
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

.social-login-btn {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
}

.social-login-btn:hover {
  background: #ff4d503b;
}

/* :where(.css-dev-only-do-not-override-1b0bdye).ant-input-outlined:hover {
  border-color: #ff4d4f;
  background-color: #ffffff;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-picker-outlined:hover {
  border-color: #ff4d4f;
  background-color: #ffffff;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-picker-outlined:focus-within {
  border-color: #ff4d4f;
  background-color: #ffffff;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #ff4d4f;
}
:where(.css-dev-only-do-not-override-1b0bdye).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):focus-within .ant-select-selector {
  border-color: #ff4d4f;
} */

:where(.css-dev-only-do-not-override-1b0bdye).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1b0bdye).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #ff4d4f;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background: #ff4d4f;
  border-color: transparent;
}

/* :where(.css-dev-only-do-not-override-1b0bdye).ant-input-outlined:focus, :where(.css-dev-only-do-not-override-1b0bdye).ant-input-outlined:focus-within {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  background-color: #ffffff;
}


:where(.css-dev-only-do-not-override-1b0bdye).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-1b0bdye).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-1b0bdye).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #ff4d4f;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #ff4d4f;
} */

:where(.css-dev-only-do-not-override-1b0bdye).ant-picker-dropdown
  .ant-picker-header-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #ff4d4f !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #ff4d4f !important;
  background-color: #ff4d4f !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-radio-wrapper:hover
  .ant-radio-inner {
  border-color: #ff4d4f !important;
  border-top-color: #ff4d4f !important;
  border-right-color: #ff4d4f !important;
  border-bottom-color: #ff4d4f !important;
  border-left-color: #ff4d4f !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-radio-wrapper
  .ant-radio-checked::after {
  border: 1px solid #ff4d4f !important;
}

#myIframe {
  height: 95vh;
  min-height: fit-content;
}

.ant-input {
  height: 40px;
}

.ant-input-password {
  height: 40px;
  display: flex;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-picker {
  height: 40px;
}

.ant-input-affix-wrapper {
  height: 40px;
  display: flex;
  align-items: center;
}
.ant-input-affix-wrapper > .ant-input {
  height: 30px;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: white !important;
  color: fieldtext !important;
}

.back-btn {
  position: sticky;
  bottom: 0;
}

@media (min-height: 600px) {
  .back-btn {
    position: absolute !important;
    bottom: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

:where(.css-1xg9z9n).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #ff4d4f !important;
  background-color: #ff4d4f !important;
}

:where(.css-1xg9z9n).ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #ff4d4f !important;
}

:where(.css-1xg9z9n).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #ff4d4f !important;
  border-color: transparent;
}

:where(.css-1xg9z9n).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-select-single {  
  height: 40px;
}

@media (max-height: 600px){
  .stage-btn {    
    height: 3.5rem;
  }
  .stage-btn > img {    
    height: 3rem;
  }
}

@media (min-height: 600px){
  .stage-btn {    
    height: 4rem;
  }
  .stage-btn > img {    
    height: 3.5rem;
  }
}

:where(.css-1xg9z9n).ant-btn-primary.ant-btn-dangerous:disabled, :where(.css-1xg9z9n).ant-btn-primary.ant-btn-dangerous.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #ff8c8e;
  color: #fff;
  background: #ff8c8e;
  box-shadow: none;
}